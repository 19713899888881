













































import { Component } from 'vue-property-decorator'

import { AbstractModal } from '../../../shared/organisms/AbstractModal'
import { ButtonLinkProps } from '../../../../dsl/atoms/ButtonLink'
import { TabOption } from '../../../../dsl/abstract/components/Link/Link.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'AddedToFavouriteClass' })
export class AddedToFavouriteClass extends AbstractModal {
  /**
   * Determines link for `favourites` page.
   */
  public get favouritePageLink (): Omit<ButtonLinkProps, 'onClick' | 'theme'> {
    return {
      label: String(this.$t('front.products.organisms.addedToFavouriteModal.link.label')),
      isDisabled: false,
      target: {
        name: 'products.me.favourites'
      },
      tabTarget: TabOption.Self
    }
  }
}

export default AddedToFavouriteClass
